import * as types from "../types";
const initialState = {
  newsletter: {},
  isLoggedIn: false,
  loading: true,
  error: null,
};

export const newsletter = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_NEWSLETTER:
      return {
        ...state,
        newsletter: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


import * as CookieConsent from "vanilla-cookieconsent";
import { serverAPI } from "../utils/envConfig";

import React, { useEffect } from "react";

export default function CookieCond() {
  function logConsent(cookie) {
    const preferences = CookieConsent.getUserPreferences();

    // We're saving only 4 fields
    const userConsent = {
      consentId: cookie.consentId,
      acceptType: preferences.acceptType,
      acceptedCategories: preferences.acceptedCategories,
      rejectedCategories: preferences.rejectedCategories,
    };

    // Send the data to the backend
    fetch(`${serverAPI}/cookieconsentlogging/log-consent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userConsent),
    });
  }
  useEffect(() => {
    document.documentElement.classList.add("cc--darkmode");
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom left",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {},
        personalization: {},
      },
      services: {
        ga: {
            label: 'Google Analytics',
            onAccept: () => {},
            onReject: () => {}
        },
        youtube: {
            label: 'Youtube Embed',
            onAccept: () => {},
            onReject: () => {}
        },
        bing: {
          label: 'Bing Embed',
          onAccept: () => {},
          onReject: () => {}
        },
        clarity: {
          label: 'Clarity.ms',
          onAccept: () => {},
          onReject: () => {}
        },
        facebook: {
          label: 'Facebook Embed',
          onAccept: () => {},
          onReject: () => {}
        },
        linkedin: {
          label: 'Linkedin Embed',
          onAccept: () => {},
          onReject: () => {}
        },
        tiktok: {
          label: 'Tiktok Embed',
          onAccept: () => {},
          onReject: () => {}
        },
        ads: {},
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "We Value Your Privacy!",
              description:
                "To enhance your experience, we use cookies to remember your preferences and recognize you on your next visit. By using our site, you agree to our use of cookies to provide a tailored browsing experience.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer:
                '<a href="/privacy-policy">Privacy Policy</a>\n<a href="/terms-and-conditions">Terms and conditions</a>',
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Settings",
                  description:
                    "When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.",
                },
                {
                  title:
                    'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description:
                    "These cookies are essential for the website's functionality, such as enabling secure login, maintaining session integrity, and ensuring basic performance. They cannot be disabled.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics Cookies",
                  description:
                    "These cookies help us understand how visitors interact with our website by collecting information anonymously. This data allows us to improve the site's functionality and user experience.",
                  linkedCategory: "analytics",
                },
                {
                  title: "Personalization Cookies",
                  description: "Personalization cookies remember your preferences and settings to provide a more personalized browsing experience. This includes remembering your language selection and displaying relevant content.",
                  linkedCategory: "personalization"
                },
                {
                  title: "More information",
                  description:
                    'For any queries about our cookie policy or to learn more about your choices, please <a href="/contact-us">Contact Us</a>',
                },
              ],
            },
          },
        },
      },
      onConsent: ({ cookie }) => {
        logConsent(cookie);
      },
    });
  }, []);
  return <></>;
}

import * as types from '../types'

const initialState = {
    favourites:[],
    isLoggedIn: false,
    loading: true,
    error: null
}

export const user = (state=initialState, action) => {
    switch(action.type){
        case types.GET_FAVOURITES:
            return {
                ...state,
                favourites: action.payload,
                loading: false,
                error: null
            }
        case types.ADD_FAVOURITE:
            return {
                ...state,
                favourites: [...state.favourites,action.payload],
                loading: false,
                error: null
            }
        case types.DEL_FAVOURITE:
            return {
                ...state,
                favourites: [...state.favourites.filter((item,index)=>item.id!==action.payload)],
                loading: false,
                error: null
            }

        default:
            return state
    }
}
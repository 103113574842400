import * as types from "../types";

const initialState = {
  buyOrRent: null,
  properties: [],
  dealOfWeek: [],
  featured: [],
  distressedAndPublished: [],
  lastUpdatedProps: [],
  amenities: [],
  communities: [],
  propertyTypes: [],
  categories: [],
  loading: true,
  error: null,
};

export const property = (state = initialState, action) => {
  switch (action.type) {
    case types.BUY_OR_RENT:
      return {
        ...state,
        buyOrRent: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_FEATURED_PROPERTIES:
      return {
        ...state,
        featured: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_DISTRESSED_PUBLISHED_PROPS:
      return {
        ...state,
        distressedAndPublished: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_LASTUPDATED_PROPERTIES:
      return {
        ...state,
        lastUpdatedProps: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_DEAL_OF_THE_WEEK:
      return {
        ...state,
        dealOfWeek: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_COMMUNITIES:
      return {
        ...state,
        communities: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_PROP_TYPES:
      return {
        ...state,
        propertyTypes: action.payload,
        loading: false,
        error: null,
      };
    case types.GET_CATEGORY:
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

//USER TYPES
export const GET_FAVOURITES = "GET_FAVOURITES";
export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const DEL_FAVOURITE = "DEL_FAVOURITE";

// PROPERTY TYPES
export const BUY_OR_RENT = "BUY_OR_RENT";
export const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES";
export const GET_DEAL_OF_THE_WEEK = "GET_DEAL_OF_THE_WEEK";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_AMENITIES = "GET_AMENITIES";
export const GET_COMMUNITIES = "GET_COMMUNITIES";
export const GET_PROP_TYPES = "GET_PROP_TYPES";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_DISTRESSED_PUBLISHED_PROPS = "GET_DISTRESSED_PUBLISHED_PROPS";
export const GET_LASTUPDATED_PROPERTIES = "GET_LASTUPDATED_PROPERTIES";
// NEWSLETTER
export const POST_NEWSLETTER = "POST_NEWSLETTER";

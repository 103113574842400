import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './reducers'


// const initialState = {}
const middleWare =  [thunk]


export const store = createStore(
    rootReducer, 
    // initialState, 
    composeWithDevTools(
        applyMiddleware(...middleWare)
        )
    )
export const persistor = persistStore(store)
export default {store, persistor}
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { user } from "./user";
import { property } from "./property";
import { newsletter } from "./newsletter";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};
const rootReducer = combineReducers({
  user,
  property,
  newsletter,
});
export default persistReducer(persistConfig, rootReducer);
